/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import {
  Col,
  Row,
  Card,
  Label,
  Input,
  Button,
  Spinner,
  CardBody,
  CardTitle,
  Container,
  FormGroup,
  CardSubtitle,
  UncontrolledTooltip,
} from "reactstrap";

import { RequestType } from "../../components/withAPI";
import Loader from "../../components/Loader";
import { ToastrSuccess } from "../../services/toastrService";
import { ErrorTable, IErrorTableRow } from "../../components/ErrorTable";
import { PermissionsCard } from "../connections/components/";
import { useAPI } from "../../components/useAPI";
import ConnectForSpreadsheetsBlocker from "../../components/ConnectForSpreadsheetsBlocker";
import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";
import { IFullPermission } from "../connections/components/PermissionsCard";
import { useQuery } from "@tanstack/react-query";
import { getConnections } from "../connections/connectionList/getConnections";
import { IConnection } from "../../models/Connections/IConnection";
import { Ops, UserRole } from "../../models";

type IFormData = {
  InviteEmailList: string[];
  Role: UserRole;
  CanBeImpersonated: boolean;
};

function InviteUsers() {
  const api = useAPI();
  const [permissions, setPermissions] = useState<IFullPermission[]>([]);
  const [permissionsUpdatedFirst, setPermissionsUpdatedFirst] = useState(false);
  const [processingRequest, setProcessingRequest] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [inviteEmailList, setInviteEmailList] = useState<string[]>([]);
  const [emailListCount, setEmaillistCount] = useState<number>(0);
  const [userSeatCount, setUserSeatCount] = useState<number>(0);
  const [emptySeatsCount, setEmptySeatsCount] = useState<number>(0);
  const [sendInviteButtonDisabled, setSendInviteButtonDisabled] =
    useState<boolean>(true);
  const [partialDisableSendInvite, setPartialDisabledSendInvite] =
    useState<boolean>(true);
  const [inviteSentFailed, setInviteSentFailed] = useState<boolean>(false);
  const [inviteSentErrorMessage, setInviteSentErrorMessage] =
    useState<string>("");
  const [errorTableTitle, setErrorTableTitle] = useState<string>("");
  const [errorTableData, setErrorTableData] = useState<IErrorTableRow[]>([]);
  const [displayErrorTable, setDisplayErrorTable] = useState<boolean>(false);
  const [failedErrorReasonMessage, setFailedErrorReasonMessage] = useState(
    <></>,
  );

  const isOnlySpreadsheetClient = useIsConnectForSpreadsheets();

  const { data: connectionListData } = useQuery({
    queryKey: ["/account/connections"],
    queryFn: () => getConnections({ IsAdmin: true, CurrentUserId: "" }),
    meta: {
      errorMessage: "Failed to get connection list due to the following error:",
    },
  });

  const initialSeatCountValidationMessage = isOnlySpreadsheetClient
    ? "Invite one or more users to your account."
    : "Note: If you are inviting multiple users at one time the role and permissions you set below will apply to all of them." +
      "You can always change their roles and permissions individually after sending.";

  const [seatCountValidationMessage, setSeatCountValidationMessage] = useState(
    initialSeatCountValidationMessage,
  );

  useEffect(() => {
    if (connectionListData != null) {
      getConnectionsData();
    }
  }, [connectionListData]); // eslint-disable-line

  useEffect(() => {
    async function fetchData() {
      await getWorkspaces();
      await getUserList();
      setLoading(false);
    }
    if (permissions !== null) fetchData();
  }, [permissionsUpdatedFirst]); // eslint-disable-line

  function getConnectionsData() {
    const connections: IConnection[] = connectionListData?.connections ?? [];
    const accountPermissions: IFullPermission[] = connections.map((x: any) => ({
      id: x.id,
      rowName: x.name,
      opsAllowed: isOnlySpreadsheetClient ? Ops.All : Ops.Select,
      type: "Connection",
      driver: x.driver,
    }));
    setPermissions([...accountPermissions]);
    setPermissionsUpdatedFirst(true);
  }

  async function getWorkspaces() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/workspaces",
      "Failed to get connection list due to the following error:",
    );
    if (status === 200) {
      const accountPermissions: IFullPermission[] = payload.workspaces.map(
        (x: any) => ({
          id: x.id,
          rowName: x.name,
          opsAllowed: 1,
          type: "Workspace",
        }),
      );
      const newAccountPermissions = [...permissions, ...accountPermissions];
      newAccountPermissions.sort((a, b) => a.rowName.localeCompare(b.rowName));

      setPermissions(newAccountPermissions);
    }
  }

  async function getUserList() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/users",
      "Failed to get user list due to the following error:",
    );

    if (status === 200) {
      setUserSeatCount(payload.userSeatCount);
      const updatedEmptySeatsCount =
        payload.userSeatCount - payload.users.length;
      setEmptySeatsCount(updatedEmptySeatsCount);
    }
  }

  function getSeatCountValidationMessage(
    emailsCount: number,
    emptySeatsCount: number,
  ): string {
    if (emailsCount > emptySeatsCount) {
      if (isOnlySpreadsheetClient) {
        return "You're trying to invite more users than your current plan allows. To add more users, please upgrade your plan.";
      }

      return `You only have ${emptySeatsCount} user seats available. Please delete ${emailsCount - emptySeatsCount}
                                     email(s) or upgrade your subscription to add more seats to your plan.`;
    }

    if (isOnlySpreadsheetClient) {
      return "Invite one or more users to your account.";
    }

    return (
      "Note: If you are inviting multiple users at one time the role and permissions you set below will apply to all of them." +
      "You can always change their roles and permissions individually after sending."
    );
  }

  function emailInputUpdated(event: React.FormEvent) {
    const target = event.target as HTMLInputElement;

    let emails = target.value.split(/[,;]+/);
    emails = emails.map((email: string) => email.trim());
    emails = emails.filter(Boolean);
    const emailsCount = emails.length;
    const updateInvitedButtonDisabled =
      emailsCount === 0 ||
      (userSeatCount > -1 &&
        emailsCount > emptySeatsCount &&
        partialDisableSendInvite);

    setSendInviteButtonDisabled(updateInvitedButtonDisabled);
    setEmaillistCount(emailsCount);
    const seatCountValidationMessage: string = getSeatCountValidationMessage(
      emailsCount,
      emptySeatsCount,
    );
    setSeatCountValidationMessage(seatCountValidationMessage);
  }

  async function handleValidSubmit(
    values: any,
    setFailedEmails: FormikHelpers<IFormData>["setFieldValue"],
  ) {
    let emails: string[] = values.InviteEmailList.split(/[,;]+/);
    emails = emails.map((email: string) => email.trim());
    setInviteEmailList(emails.filter(Boolean));

    setProcessingRequest(true);

    const accountPermissions: IFullPermission[] = permissions;
    const data: { invites?: any[] } = {};
    const connectionPermissions = accountPermissions.filter(
      (item) => item.type === "Connection",
    );
    const workspacePermissions = accountPermissions.filter(
      (item) => item.type === "Workspace",
    );
    const connectionPermissionList = connectionPermissions.map(
      (permission) => ({
        ConnectionId: permission.id,
        OpsAllowed: permission.opsAllowed,
      }),
    );
    const workspacePermissionList = workspacePermissions.map((permission) => ({
      WorkspaceId: permission.id,
      OpsAllowed: permission.opsAllowed,
    }));
    data.invites = emails.map((email) => ({
      Email: email,
      Permissions: connectionPermissionList,
      WorkspacePermissions: workspacePermissionList,
      Role: values.Role,
      CanBeImpersonated:
        values.Role.toString() === "0" ? true : values.CanBeImpersonated,
    }));
    //reset all error messages right before new api call
    resetAllErrorMessages();

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/users/invite",
      "Failed to invite users due to the following error:",
      data,
    );
    if (status === 200) {
      setProcessingRequest(false);
      const data = payload;

      //some email went sucessful and some failed
      if (
        data.successful &&
        data.successful.length > 0 &&
        data.failed &&
        data.failed.length > 0
      ) {
        handleErrorTable(data);
        setDisplayErrorTable(true);

        //removes successful emails, only failed ones remain on input field
        const failedEmails = data.failed
          .map((item: any) => {
            return `${item.email}`;
          })
          .toString();

        // formik actions.setFieldValue function passed here, to prevent delayed setState,
        // when reseting displayed only failed emails
        setFailedEmails("InviteEmailList", failedEmails);
      }

      //atleast 1 email successfully sent
      if (data.successful && data.successful.length > 0) {
        ToastrSuccess(
          `${data.successful.length === 1 ? "Invite" : "Invites"} successfully sent`,
          `Successfully invited ${data.successful.length} ${data.successful.length === 1 ? "user" : "users"} to CData Connect Cloud.`,
        );
      }

      //only one email present and failed for any reason
      if (data.failed && data.failed.length === 1) {
        handleInviteErrorMessages(data);

        //more than one email failed for any reason or all failed
      } else if (data.failed && data.failed.length > 1) {
        handleErrorTable(data);
        setDisplayErrorTable(true);
        setInviteSentFailed(true);
      }
    } else {
      setProcessingRequest(false);
    }
  }

  function resetAllErrorMessages() {
    setInviteSentFailed(false);
    setInviteSentErrorMessage("");
    setFailedErrorReasonMessage(<></>);
    setErrorTableTitle("");
    setErrorTableData([]);
    setDisplayErrorTable(false);
  }

  function handleErrorTable(data: any) {
    const oneUserFailed = "1 User Invite Failed";
    const morethanOneUserFailed = `${data.failed.length} User Invites Failed`;

    const errorTableData = data.failed.map(
      (dataItem: any, index: number): IErrorTableRow | undefined => {
        if (dataItem.reason === "INVALID_EMAIL_ADDRESS") {
          return {
            name: dataItem.email,
            errorMessage: `Failed to invite ${dataItem.email}, not a valid email address.`,
          };
        }

        if (dataItem.reason === "CANNOT_INVITE_USER") {
          return {
            name: dataItem.email,
            errorMessage: `Failed to invite ${dataItem.email}. Please use a different email address and try again.`,
          };
        }

        if (dataItem.reason === "EXISTING_INVITE") {
          return {
            name: dataItem.email,
            errorMessage: `Failed to send invite because ${dataItem.email} has already been invited.`,
          };
        }

        if (dataItem.reason === "ERROR") {
          return {
            name: dataItem.email,
            errorMessage: (
              <>
                Failed to invite {dataItem.email}.{" "}
                <Label
                  id={"id" + index.toString()}
                  className="underline-learn-more"
                >
                  Learn more
                </Label>
                <UncontrolledTooltip
                  placement="top"
                  target={"id" + index.toString()}
                  trigger="hover"
                >
                  {dataItem.message}
                </UncontrolledTooltip>
              </>
            ),
          };
        }
      },
    );

    if (data.failed.length === 1) {
      setErrorTableTitle(oneUserFailed);
      setErrorTableData(errorTableData);
    }

    if (data.failed.length > 1) {
      setErrorTableTitle(morethanOneUserFailed);
      setErrorTableData(errorTableData);
    }
  }

  function handleInviteErrorMessages(data: any) {
    if (data.failed[0].reason === "INVALID_EMAIL_ADDRESS") {
      setInviteSentFailed(true);
      setInviteSentErrorMessage(
        `Failed to invite ${data.failed[0].email}, not a valid email address`,
      );
    }

    if (data.failed[0].reason === "CANNOT_INVITE_USER") {
      setInviteSentFailed(true);
      setInviteSentErrorMessage(
        `Failed to invite ${data.failed[0].email}. Please use a different email address and try again.`,
      );
    }

    if (data.failed[0].reason === "EXISTING_INVITE") {
      setInviteSentFailed(true);
      setInviteSentErrorMessage(
        `Failed to send invite because ${data.failed[0].email} has already been invited`,
      );
    }

    if (data.failed[0].reason === "ERROR") {
      setInviteSentFailed(true);
      setInviteSentErrorMessage(`Failed to invite ${data.failed[0].email}. `);
      setFailedErrorReasonMessage(
        <>
          <Label
            for="otherError"
            id="otherError"
            className="underline-learn-more"
          >
            Learn more
          </Label>
          <UncontrolledTooltip
            placement="top"
            target="otherError"
            trigger="hover"
          >
            {data.failed[0].message}
          </UncontrolledTooltip>
        </>,
      );
    }
  }

  function handleOnFocus() {
    setPartialDisabledSendInvite(true);
  }

  function handleOnBlur() {
    setPartialDisabledSendInvite(false);
  }

  function renderMultiUserInviteCard() {
    const initialFormValues: IFormData = {
      InviteEmailList: inviteEmailList,
      Role: 0,
      CanBeImpersonated: false,
    };

    return (
      <Formik
        initialValues={initialFormValues}
        onSubmit={(values, actions) => {
          handleValidSubmit(values, actions.setFieldValue);
        }}
      >
        {({ handleBlur, handleChange, handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="mb-4">
                <NavLink
                  className="align-middle text-dark"
                  to={{
                    pathname: "/user",
                  }}
                >
                  <i className="fa fa-arrow-left fa-lg back-arrow" />
                </NavLink>
                <h1 className="h3 d-inline align-middle h3 ms-3">
                  Invite Users
                </h1>
              </Col>
              <Col className="text-end">
                <Button
                  type="submit"
                  color="primary"
                  disabled={sendInviteButtonDisabled}
                  data-testid="send-invite-button"
                >
                  <i className="fa fa-envelope icon no-pointer-event" />
                  Send Invite
                </Button>
              </Col>
            </Row>

            {displayErrorTable && (
              <Row>
                <Col>
                  <Card data-testid="error-table">
                    <ErrorTable
                      title={errorTableTitle}
                      entityName="Email"
                      data={errorTableData}
                    />
                  </Card>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardSubtitle tag="h6" className="mt-2 mb-4 text-muted">
                      Enter the email address of the users you would like to
                      invite to CData Connect Cloud.
                    </CardSubtitle>
                    <CardTitle tag="h3" className="mb-3">
                      Email
                    </CardTitle>
                    <Form.Group
                      className={
                        (emailListCount > emptySeatsCount &&
                          userSeatCount > -1) ||
                        inviteSentFailed
                          ? "last-form-field user-seat-error-focus-color"
                          : "last-form-field"
                      }
                    >
                      <Form.Control
                        id="InviteEmailList"
                        name="InviteEmailList"
                        value={values.InviteEmailList}
                        placeholder="johnsmith@company.com, janesmith@company.com,"
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        onChange={handleChange}
                        onInput={emailInputUpdated}
                        data-testid="email-input"
                      />
                    </Form.Group>

                    {/*When availableSeatCount is unlimited this div will not display*/}
                    <div
                      className={
                        (emailListCount > emptySeatsCount &&
                          userSeatCount > -1) ||
                        inviteSentFailed
                          ? "user-seat-validation user-seat-error-font-color"
                          : "user-seat-validation"
                      }
                    >
                      {userSeatCount !== -1 && seatCountValidationMessage}
                    </div>

                    {/*Invite(s) sent failed*/}
                    <div
                      className={
                        emailListCount > 0 && inviteSentFailed
                          ? "user-seat-validation user-seat-error-font-color"
                          : "user-seat-validation"
                      }
                    >
                      {inviteSentErrorMessage}
                      {failedErrorReasonMessage}
                    </div>

                    <CardTitle tag="h3" className="mt-4 mb-3" for="role">
                      Role
                    </CardTitle>
                    <FormGroup className="last-form-field">
                      <Input
                        name="Role"
                        type="select"
                        defaultValue={values.Role}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={isOnlySpreadsheetClient}
                      >
                        <option key="0" value="0">
                          Administrator
                        </option>
                        <option key="1" value="1">
                          Query
                        </option>
                      </Input>
                    </FormGroup>
                    {(values.Role as unknown) === "1" ? (
                      <>
                        <div className="toggle-btn">
                          <CardTitle tag="h3" className="mt-4 mb-3">
                            Support Access Privileges
                          </CardTitle>
                          <div className="switch">
                            <FormGroup
                              switch
                              className="form-check form-switch custom-form"
                            >
                              <Input
                                type="switch"
                                name="CanBeImpersonated"
                                role="switch"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div>
                          Once enabled, a Query user can authorize support
                          access to their account through their Account
                          Settings.
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </CardBody>
                </Card>
                <ConnectForSpreadsheetsBlocker>
                  <PermissionsCard
                    permissions={permissions}
                    updatePermissions={setPermissions}
                    rowColumnName={"Entity"}
                  />
                </ConnectForSpreadsheetsBlocker>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }

  const contents = loading ? (
    <Container fluid className="p-0">
      <Loader />
    </Container>
  ) : (
    renderMultiUserInviteCard()
  );

  return (
    <Container fluid className="p-0 pages-users-InviteUsers">
      <div hidden={!processingRequest}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      {contents}
    </Container>
  );
}

export default InviteUsers;
